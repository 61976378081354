import React, { useState, useEffect, useContext } from 'react'
import { Web3Context } from '../providers/Web3Provider'
import Blockies from './Blockies'
import { UserContext } from '../providers/UserProvider'
import { MetaMaskButton } from 'rimble-ui'

const ConnectionStatus = () => {
    const [network, setNetwork] = useState()
    const { web3 } = useContext(Web3Context)
    const { accounts, setAccounts, provider, metaMask } = web3
    const user = useContext(UserContext)

    const connectedNetwork = () => network ? <><i className="petalicon petalicon-globe text-green"></i>&nbsp;{network}</> : '🔴 Disconnected'
    const connectedAddress = () => accounts?.[0]?.toLowerCase()
    const connectedUser = () => user?.ens || accounts?.[0]

    const handleConnectButtonOnClick = () => metaMask?.request({ method: 'eth_requestAccounts' }).then(accts => setAccounts([accts?.[0]]))

    useEffect(() => {
        let fetchData = async () => {
            let nn = await provider?.getNetwork()
            setNetwork(nn?.name)
        }
        fetchData()
    }, [provider, metaMask, network, accounts])


    return <span className='p-10'>
        {connectedAddress() && <p><i className="petalicon petalicon-user text-blue"></i>&nbsp;{connectedUser()}<br />
            {connectedNetwork()}
        </p>}

        {!connectedAddress() && <MetaMaskButton.Outline onClick={() => handleConnectButtonOnClick()}>Connect with MetaMask</MetaMaskButton.Outline>}<br />
    </span>
}

export default ConnectionStatus