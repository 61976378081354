import config from '../config/default'

const domain = config.contract.domain

const types = {
    LISTING_TYPE: {
        Listing: [
            { name: 'seller', type: 'address' },
            { name: 'content', type: 'string' },
            { name: 'active', type: 'bool' }
        ]
    },
    MESSAGE_TYPE: {
        Message: [
            { name: 'sender', type: 'address' },
            { name: 'to', type: 'address' },
            { name: 'location', type: 'bytes32' },
            { name: 'active', type: 'bool' }
        ]
    }
}

export { domain, types }