import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { create } from "kubo-rpc-client";
import { digest } from "multiformats";
import { CID } from "kubo-rpc-client";
const DAG_PB_CODEC = 0x70;
const SHA2_256_CODE = 0x12;

const IPFSContext = React.createContext();
const IPFSProvider = ({ children }) => {
  const [ipfs, setIpfs] = useState();

  const initIPFS = async () => {
    const ipfs = await create({
      host: "ipfs.infura.io",
      port: 5001,
      protocol: "https",
      headers: {
        // TODO: Move this server-side, this is not secure!!!!!
        authorization: `Basic ${Buffer.from(
          process.env.API_KEY + ":" + process.env.API_KEY_SECRET
        ).toString("base64")}`,
      },
    });
    setIpfs(ipfs);
  };

  useEffect(() => {
    initIPFS();
  }, []);

  const cat = async (cid) => {
    const decoder = new TextDecoder();
    let content = "";

    for await (const chunk of ipfs.cat(cid)) {
      content += decoder.decode(chunk, {
        stream: true,
      });
    }

    return JSON.parse(content);
  };

  const write = async (data) => {
    if (!ipfs) {
      return null;
    }

    const fileToAdd = {
      path: `${data.id || "data"}`,
      content: new TextEncoder().encode(JSON.stringify(data)),
    };
    const file = await ipfs
      .add(fileToAdd, {
        progress: (prog) => console.log(`received: ${prog}`),
      })
      .catch((err) => {
        console.error(`ipfs: could not write cid ${cid}`);
        console.error(err);
      });

    console.log(`wrote file path: ${file.path}`);
    const cid = file.cid;
    console.log(`ipfs written. cid: ${cid.toString()}`);
    //TODO: verify integrity of cid (cid.multihash.code === 18)


    const bytes32 = ethers.utils.hexlify(cid?.multihash?.digest);
    return bytes32;
  };

  const read = async (bytes32) => {
    if (!ipfs) {
      return null;
    }

    const bytes = ethers.utils.arrayify(bytes32);

    const d = digest.create(SHA2_256_CODE, bytes);
    const cid = CID.create(1, DAG_PB_CODEC, d);

    console.log(`ipfs: recreated cid from bytes: ${cid}`);

    const result = await cat(cid).catch((err) => {
      console.error(`ipfs: could not read cid ${cid}`);
      console.error(err);
    });

    return result;
  };

  return (
    <IPFSContext.Provider value={{ read, write }}>
      {children}
    </IPFSContext.Provider>
  );
};

export { IPFSProvider, IPFSContext };
