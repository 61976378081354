import React, { useEffect, useState, useContext } from 'react'
import { Web3Context } from '../providers/Web3Provider'
import Blockies from './Blockies'
import { UserContext } from '../providers/UserProvider'
import { IPFSContext } from '../providers/IPFSProvider'
import { Link } from '@reach/router'

const Inbox = () => {
    const [messages, setMessages] = useState([])

    const ipfs = useContext(IPFSContext)
    const { web3 } = useContext(Web3Context)
    const { contract, metaMask, accounts, provider } = web3
    const user = useContext(UserContext)

    const fetchMessages = async () => {
        const toMe = await contract?.queryFilter(contract?.filters.MessageEvent(null, user.id))

        const events = await Promise.all(
            toMe?.map(async (e) => {
                const block = await provider.getBlock(e.blockNumber)
                return {
                    blockNumber: e.blockNumber,
                    timestamp: (new Date(block.timestamp * 1000)).toLocaleString(),
                    sender: e.args.sender,
                    location: e.args.location
                }}))
        const messages = events?.sort((a, b) => a.blockNumber < b.blockNumber ? 1 : -1)
        setMessages(messages)
    }
    const setDecryptResult = (id, result) =>
        setMessages(prev => {
            prev.forEach(item => {
                if (item.location === id) { item.decrypted = JSON.parse(result) }
            })
            return [...prev]
        })

    useEffect(() => {
        if (user.id) {
            fetchMessages()
        }
    }, [contract, user.id])

    return <>
        <h3>Your Messages</h3>
        {messages?.map((msg, i) =>
            <>
                <div key={i} className="card hover-em p-20 mv-20">
                    <div  className="card hover-em p-20 mv-20">
                    From: <Blockies address={msg?.sender.toLowerCase()} imageSize='20' />
                        {msg?.sender}<br />
                    Sent: {msg?.timestamp?.toLocaleString()}
                        {msg.decrypted &&
                            <>
                                <pre className="p-20">{msg.decrypted.message}</pre>
                                <pre className="p-20">{msg.decrypted.originalMessage &&
                                    `\r\n-------------Original Message-------------\r\n${msg.decrypted.originalMessage}`
                                }</pre>
                            </>
                        }
                    </div>
                    {msg.decrypted ?
                        <>
                            <Link
                                to={`/messaging/send/${msg?.sender.toLowerCase()}`}
                                state={{
                                    toPublicEncryptionKey: msg.decrypted.replyToEncryptionKey,
                                    originalMessage: msg.decrypted.message + '\r\n-------------Original Message-------------\r\n' + msg.decrypted.originalMessage
                                }}
                            ><button className="btn hollow">Reply</button></Link>
                        </>
                        : <input type='button' className='btn' value='decrypt' onClick={async () => {
                            let encryptedMessage = await ipfs.read(msg?.location)
                                .catch(err => {
                                    setDecryptResult(msg.location, 'ERROR: Unable to read IPFS.')
                                    throw new Error(err)
                                })

                            metaMask
                                .request({
                                    method: 'eth_decrypt',
                                    params: [encryptedMessage,
                                        accounts[0]],
                                })
                                .then((decryptedMessage) => setDecryptResult(msg.location, decryptedMessage))
                                .catch((err) => {
                                    console.error(err)
                                    setDecryptResult(msg.location, 'ERROR: Unable to decrypt.')
                                })
                        }} />}
                </div>
            </>
        )}
    </>
}
export default Inbox