import React, { useEffect, useState, useContext } from 'react'
import { Link } from '@reach/router'

import { types, domain } from '../contract/contract'
import Blockies from './Blockies'
import { Web3Context } from '../providers/Web3Provider'
import { UserContext } from '../providers/UserProvider'
import { IPFSContext } from '../providers/IPFSProvider'

const Listings = () => {
    const [listings, setListings] = useState([])

    const { web3 } = useContext(Web3Context)
    const ipfs = useContext(IPFSContext)
    const { contract, ethers } = web3
    const user = useContext(UserContext)

    const allEqual = (array) =>
        (new Set(array)).size === 1

    const verifyListings = (listings, address, publicEncryptionKey) =>
        listings?.map(l => ({
            ...l, ...{
                verified: l?.sig &&
                    allEqual([address, l.seller, ethers.utils.verifyTypedData(domain, types.LISTING_TYPE, l, l.sig)]),
                publicEncryptionKey: publicEncryptionKey
            }
        }))

    const fetchListings = async () =>
        contract?.getUserCount().then(c => {
            for (let i = 0; i < c.toNumber(); i++) {
                contract.users(i).then((addr) => {
                    if (addr.toLowerCase() !== user.id.toLowerCase()) {
                        contract.user_data(addr).then(async (bytes32) => {
                            let userData = await ipfs.read(bytes32)
                            if (!userData?.listings) return
                            let verifiedListings = verifyListings(userData.listings, addr, userData.publicEncryptionKey)
                            setListings((prevState) => [...prevState, ...verifiedListings])
                        })
                    }
                })
            }
        })

    useEffect(() => {
        if (!user?.id || !contract) return
        setListings([])
        fetchListings()
    }, [contract, user?.id])

    return <>
        <h3>Listings</h3>

        {listings?.map((listing, i) =>
            listing.active && <div key={i} className="card hover-em">
                <div className="p-20 mv-10">
                    <p><Blockies address={listing.seller.toLowerCase()} imageSize='20' />
                        {listing.seller} {listing.verified ? <i className="petalicon petalicon-check-round-filled text-blue"></i> : '❗'}</p>
                    <pre className='p-20 mv-20'>{listing?.content}</pre>
                    <Link
                        to={`/messaging/send/${listing.seller}`}
                        state={{ toPublicEncryptionKey: listing.publicEncryptionKey, originalMessage: listing?.content }}
                    ><button className="btn hollow">Send Message</button></Link>
                </div>
            </div>
        )}
    </>
}
export default Listings