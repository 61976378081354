import React, { useEffect, useState, useContext } from 'react'
import { Web3Context } from './Web3Provider'
import { IPFSContext } from '../providers/IPFSProvider'

const UserContext = React.createContext()

const userFound = (bytes) =>
    bytes &&
    bytes !== '0x0000000000000000000000000000000000000000000000000000000000000000'

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        isLoggedIn: false,
        data: null,
        id: null,
        ens: null
    })

    const { web3 } = useContext(Web3Context)
    const ipfs = useContext(IPFSContext)
    const { accounts, contract, provider } = web3

    const lookupEns = async (addr) => {
        if (!addr) return null
        return await provider?.lookupAddress(addr).then(maybeEns => (maybeEns || addr))
    }

    const fetchUserData = async () => {
        if (!(accounts && contract && ipfs)) return

        const userDataCidBytes = await contract?.user_data(accounts[0]).catch(console.error)

        let userData = userFound(userDataCidBytes)
            ? await ipfs.read(userDataCidBytes).catch(console.error)
            : null

        let updated = {
            isLoggedIn: userFound(userDataCidBytes),
            data: userData,
            id: accounts[0],
            ens: await lookupEns(accounts[0])
        }
        setUser(prev => ({ ...prev, ...updated }))
    }

    useEffect(() => {
        fetchUserData()
    }, [accounts, contract, provider, ipfs])

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export { UserProvider, UserContext }