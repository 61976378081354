import React, { useState, useEffect, useContext } from "react";
import { Web3Context } from "../providers/Web3Provider";
import { UserContext } from "../providers/UserProvider";

//import ConnectionBanner from '@rimble/connection-banner'

const Home = () => {
  const { web3 } = useContext(Web3Context);
  const { provider, metaMask } = web3;
  const user = useContext(UserContext);

  return (
    <>
      {
        !metaMask && (
          <p style={{ color: "red" }}>ERROR: Metamask is not installed</p>
        )
        // <ConnectionBanner
        //     currentNetwork={metaMask?.chainId}
        //     requiredNetwork={1}
        //     onWeb3Fallback={true}
        // />
      }
      <h4>What is it?</h4>
      <p>
        Wyvern Trail is a secure blockchain marketplace that allows buyers and
        sellers to transact in a secure and decentralized manner. Anyone can
        post a listing and recieve offers via the secure messaging system.
      </p>
      <p>
        Messages are encrypted and stored in IPFS so they cannot be read or
        censored by any 3rd party.
      </p>
      <h4>Is it ready to use?</h4>
      <p>
        We are currently testing on the goerli Ethereum TestNet. If you would
        like to help test, please set your MetaMask network to{" "}
        <strong>Goerli Test Network</strong>.
      </p>
    </>
  );
};

export default Home;
