import React from 'react'
import ReactDom from 'react-dom'

import Listings from './components/Listings'
import CreateListing from './components/CreateListing'

import Inbox from './components/Inbox'
import { UserProvider } from './providers/UserProvider'
import { Web3Provider } from './providers/Web3Provider'
import Nav from './components/Nav'
import ManageListings from './components/ManageListings'
import { Router } from '@reach/router'
import 'dotenv/config'
import SendMessage from './components/SendMessage'
import 'petal.less/dist/petal.min.css'
import 'petal.less/dist/petal.min.js'
import ConnectionStatus from './components/ConnectionStatus'
import Home from './components/Home'
import wyvern from 'url:./img/wyvern-blue.png?height=125'
import { IPFSProvider } from './providers/IPFSProvider'

const App = () => {
    return (
        <React.StrictMode>
            <IPFSProvider>
                <Web3Provider>
                    <UserProvider>
                        <header className="container p-20">
                            <div className="container row">
                                <div className="col-5">
                                    <img src={wyvern} />
                                </div>
                                <div className="col-5 colspan-3">
                                    <h2>Wyvern Trail <small>Blockchain Marketplace</small></h2>
                                    <Nav /></div>
                                <div className="col-5"><ConnectionStatus></ConnectionStatus></div>
                            </div>
                        </header>
                        <section className="container p-20">
                            <Router>
                                <Home path="/"></Home>
                                <Listings path="listings"></Listings>
                                <ManageListings path="listings/manage"></ManageListings>
                                <CreateListing path="listings/manage/new"></CreateListing>
                                <SendMessage path="messaging/send/:toAddress"></SendMessage>
                                <Inbox path="inbox"></Inbox>
                            </Router>
                        </section>
                    </UserProvider>
                </Web3Provider>
            </IPFSProvider>
        </React.StrictMode>
    )
}
ReactDom.render(<App />, document.getElementById('app'))