const contractAddress = '0x1c0aA3e61DC3de82bc3962e99D673Ef26B69E477'
const config = {
    contract: {
        address: contractAddress,
        domain: {
            name: 'Wyvern Trail',
            version: '0.1',
            chainId: 5,
            verifyingContract: contractAddress
        }
    }
}

export default config