import React, { useState, useContext } from 'react'
import { types, domain } from '../contract/contract'
import { encrypt } from 'eth-sig-util'
import Blockies from './Blockies'
import { Web3Context } from '../providers/Web3Provider'
import { UserContext } from '../providers/UserProvider'
import { IPFSContext } from '../providers/IPFSProvider'

const SendMessage = ({ toAddress, location }) => {
    const { toPublicEncryptionKey, originalMessage } = location.state
    //TODO: lookup encryption key if not passed (refreshing page)

    const [encryptedContent, setEncryptedContent] = useState()
    const [status, setStatus] = useState()
    const { web3 } = useContext(Web3Context)
    const ipfs = useContext(IPFSContext)
    const { signer, contract, accounts, ethers, metaMask } = web3
    const user = useContext(UserContext)

    const encryptText = (text, replyToEncryptionKey) => ethers?.utils.hexlify(Buffer.from(JSON.stringify(
        encrypt(
            toPublicEncryptionKey,
            { data: JSON.stringify({
                message: text,
                replyToEncryptionKey,
                originalMessage
            })},
            'x25519-xsalsa20-poly1305'
        )
    )))

    //todo common function
    const setupPublicEncryptionKey = async () => {
        let publicEncryptionKey = await metaMask.request({
            method: 'eth_getEncryptionPublicKey',
            params: [accounts[0]],
        })
            .catch((err) => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })
        await saveUserData({ ...user, publicEncryptionKey: publicEncryptionKey })
    }
    //todo common function
    const saveUserData = async (userData) => {
        const cid_bytes =
            await ipfs.write(userData)
                .catch(err => {
                    setStatus(`Error (${JSON.stringify(err)})`)
                    throw new Error(err)
                })

        console.log(`Saved to ipfs: cid_bytes=${cid_bytes}`)


        contract.register(cid_bytes)
            .then(res => setStatus(`Success! (tx hash ${res.hash})`))
            .catch(err => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })

        // TODO: refresh data from blockchain
        //setUserListings((prevState) => [...prevState, newListing])
    }
    if (!(toAddress && toPublicEncryptionKey)) return null
    if (status) return <p>{status}</p>

    if (!user.data?.publicEncryptionKey) {
        return <div>
            <p>To send messages, you&apos;ll need to store your public encryption key so that users can send you encrypted messages.</p>
            <input type="button" className="btn" value="Setup PublicEncryptionKey" disabled={user.data?.publicEncryptionKey} onClick={async () => await setupPublicEncryptionKey()} />
        </div>
    }

    return <>
        <p>To: <Blockies address={toAddress.toLowerCase()} imageSize='20' /> {toAddress}</p>
        <p>Message: </p>
        <p><textarea onChange={e => setEncryptedContent(encryptText(e.target.value, user.data.publicEncryptionKey))
        } /></p>
        <input type='button' className='btn' value='send message' disabled={!encryptedContent} onClick={async () => {
            const cid_bytes =
                await ipfs.write(encryptedContent)
                    .catch(err => {
                        setStatus(`Error (${JSON.stringify(err)})`)
                        throw new Error(err)
                    })
            let message = {
                sender: accounts[0],
                to: toAddress,
                location: cid_bytes,
                active: true
            }
            signer?._signTypedData(domain, types.MESSAGE_TYPE, message).then(sig => {
                contract.send(message, sig)
                    .then(res => setStatus(`Sent! (tx hash ${res.hash})`))
                    .catch(err => {
                        setStatus(`Error (${JSON.stringify(err)})`)
                        throw new Error(err)
                    })
            })
        }} />
    </>
}
export default SendMessage