import React, { useState, useContext } from 'react'
import { UserContext } from '../providers/UserProvider'
import { domain, types } from '../contract/contract'
import { Web3Context } from '../providers/Web3Provider'
import { IPFSContext } from '../providers/IPFSProvider'


const CreateListing = () => {
    const { web3 } = useContext(Web3Context)
    const ipfs = useContext(IPFSContext)
    const { accounts, signer, contract, metaMask } = web3
    const user = useContext(UserContext)

    const [listing, setListing] = useState({})
    const [status, setStatus] = useState()

    //todo common function
    const setupPublicEncryptionKey = async () => {
        let publicEncryptionKey = await metaMask.request({
            method: 'eth_getEncryptionPublicKey',
            params: [accounts?.[0]],
        })
            .catch((err) => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })
        let newUserData = { user: accounts[0], publicEncryptionKey: publicEncryptionKey, listings: [] }

        await saveUserData(newUserData)
    }

    const signListing = async () => {
        listing.active = true
        listing.seller = await signer.getAddress()
        listing.sig = await signer._signTypedData(domain, types.LISTING_TYPE, listing)
            .catch((err) => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })
        setListing(prev => ({ ...prev, ...listing }))
        return listing
    }
    const saveListing = () => {
        if (!user?.data?.publicEncryptionKey) {
            setStatus('Error: Please setup your encryption key before posting so that people can respond to your listing.')
            return
        }

        if (listing) {
            user.data.listings = [...(user.data.listings || []), listing]
            saveUserData(user.data)
        }
    }

    //todo common function
    const saveUserData = async (userData) => {

        const cid_bytes =
            await ipfs.write(userData)
                .catch(err => {
                    setStatus(`Error (${JSON.stringify(err)})`)
                    throw new Error(err)
                })

        console.log(`Saved to ipfs: cid_bytes=${cid_bytes}`)


        contract.register(cid_bytes)
            .then(res => setStatus(`Success! (tx hash ${res.hash})`))
            .catch(err => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })

        // todo: refresh data from blockchain
        //setUserListings((prevState) => [...prevState, newListing])
    }

    if (status) {
        return <p>{status}</p>
    }

    if(!user.data?.publicEncryptionKey) {
        return <div>
            <p>To add a listing, you&apos;ll need to store your public encryption key so that users can send you encrypted messages.</p>
            <input type="button" className="btn" value="Setup Public Encryption Key" onClick={async () => await setupPublicEncryptionKey()} />
        </div>
    }

    return <>
        <p>Create New Listing:</p>
        <p><textarea onChange={(event => {
            event.persist() // todo: react 16 fix, remove in 17
            setListing(prevState => ({ ...prevState, ...{ content: event.target.value } }))
        }
        )} /></p>
        <p>
            <input type="button" className="btn" value="Sign" disabled={!listing?.content || listing.sig} onClick={async () => await signListing()} />
            <input type="button" className="btn" value="Publish" disabled={!listing?.sig} onClick={() => saveListing()} />

        </p>
    </>
}

export default CreateListing