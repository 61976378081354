import React from 'react'
import { Link, Location } from '@reach/router'

const Nav = () => {
    const navLinks = [
        { to: '/', text: 'Home' },
        { to: '/listings', text: 'Listings' },
        { to: '/listings/manage', text: 'My Listings' },
        { to: '/inbox', text: 'Inbox' }
    ]
    return <nav>
        <div className="tab-group tab-style-01">
            <Location>
                {({ location }) =>
                    navLinks.map((lnk, i) =>
                        <Link key={lnk.to} to={lnk.to}>
                            <div className={`tab ${location.pathname === lnk.to ? 'selected' : ''}`} >
                                <span className="tab-label">{lnk.text}</span>
                            </div>
                        </Link>
                    )
                }
            </Location>
            <div className="tab spacer"></div>
        </div>


    </nav>
}

export default Nav