import React, { useState, useEffect, useContext } from 'react'
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers'
import { abi } from '../contract/abi'
import config from '../config/default'
import { IPFSContext } from '../providers/IPFSProvider'

const Web3Context = React.createContext()
const Web3Provider = ({ children }) => {
    const ipfs = useContext(IPFSContext)

    const [metaMask, setMetaMask] = useState()
    const [provider, setProvider] = useState()
    const [signer, setSigner] = useState()
    const [contract, setContract] = useState()
    const [accounts, setAccounts] = useState()

    const initEthers = async () => {
        let mm = await detectEthereumProvider()

        setMetaMask(mm)
        if (mm?.selectedAddress) setAccounts([mm.selectedAddress])
        mm?.on('accountsChanged', function (a) {
            setAccounts(a)
        })

        let p = new ethers.providers.Web3Provider(mm)
        setProvider(p)
        let s = p.getSigner()
        setSigner(s)

        provider?.on('network', (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload()
            }
        })
        let c = new ethers.Contract(config.contract.address, abi, s)
        setContract(c)
    }
    useEffect(() => {
        initEthers()
    }, [window.ethereum, ipfs])

    let value = {
        web3: { accounts, setAccounts, provider, signer, metaMask, ethers, contract }
    }

    return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>
}

export { Web3Provider, Web3Context }