import React, { useState, useContext } from 'react'
import { UserContext } from '../providers/UserProvider'
import { domain, types } from '../contract/contract'
import { Web3Context } from '../providers/Web3Provider'
import { IPFSContext } from '../providers/IPFSProvider'
import { Link } from '@reach/router'

const ManageListings = () => {
    const { web3 } = useContext(Web3Context)
    const ipfs = useContext(IPFSContext)
    const { signer, contract } = web3
    const user = useContext(UserContext)
    const [status, setStatus] = useState()

    const deleteListing = async (toDelete) => {
        const existingListings = user.data.listings.filter(l => l.sig !== toDelete.sig)

        toDelete.active = false
        toDelete.sig = await signer._signTypedData(domain, types.LISTING_TYPE, toDelete)
            .catch((err) => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })


        if (!user?.data?.publicEncryptionKey) {
            setStatus('Error: Please setup your encryption key before posting so that people can respond to your listing.')
            return
        }

        if (toDelete) {
            user.data.listings = [...existingListings, toDelete]
            saveUserData(user.data)
        }
        return toDelete
    }

    //todo common function
    const saveUserData = async (userData) => {
        const cid_bytes =
            await ipfs.write(userData)
                .catch(err => {
                    setStatus(`Error (${JSON.stringify(err)})`)
                    throw new Error(err)
                })

        console.log(`Saved to ipfs: cid_bytes=${cid_bytes}`)


        contract.register(cid_bytes)
            .then(res => setStatus(`Success! (tx hash ${res.hash})`))
            .catch(err => {
                setStatus(`Error (${JSON.stringify(err)})`)
                throw new Error(err)
            })
    }

    if (status) {
        return <p>{status}</p>
    }
    return <>
        <h3>My Listings</h3>
        {user.data?.listings?.map((listing, i) =>
            listing.active && <div key={i} className="card hover-em p-20 mv-10">
                <pre className='p-20 mv-20'>{listing?.content}</pre>
                <input type="button" className="btn" value="Delete" disabled={listing.active === false} onClick={async () => await deleteListing(listing)} />
            </div>
        )}

        <Link to="/listings/manage/new"><i className="petalicon petalicon-plus"></i> Add Listing</Link>
    </>
}
export default ManageListings